<template>
  <b-card
    class="overflow-visble"
    no-body
  >
    <b-table
      :items="categories"
      :fields="fields"
      striped
      responsive="sm"
      empty-text="No se han registrado ninguna categoría"
    >
      <template #cell(tools)="data">
        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >

          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: 'CategoriesEditForm', params: { id: data.item.id } }">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Editar</span>
          </b-dropdown-item>

          <b-dropdown-item @click="showConfirmationModal(data.item)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Eliminar</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import {
  BCard, BTable, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BTable,
    BDropdown,
    BDropdownItem,
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Nombre',
        },
        {
          key: 'tools',
          label: 'herramientas',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('categories', ['categories']),
  },
  methods: {
    ...mapActions('categories', ['deleteCategory']),
    showConfirmationModal(category) {
      this.$bvModal.msgBoxConfirm('Porfavor confirma que deseas eliminar el registro actual', {
        title: 'Mensaje de confirmación',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'SÍ',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value === true) {
            this.deleteCategory({ category: { id: category.id, active_status: false } })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped></style>
