<template>
  <div>
    <b-row>
      <b-col class="d-flex justify-content-between pb-1 align-items-center">
        <h3 class="font-weight-bolder">
          Categorías
          <b-badge variant="dark">
            {{ pagination.total_objects }}
          </b-badge>
        </h3>
        <b-button
          :to="{ name:'CategoriesForm' }"
          variant="primary"
        >
          Alta de categorías
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <categories-table />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BButton,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import CategoriesTable from '@/@core/components/categories/CategoriesTable.vue'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
    CategoriesTable,
  },
  computed: {
    ...mapGetters('categories', ['pagination']),
  },
  mounted() {
    this.getCategories({ by_active_status: true })
  },
  methods: {
    ...mapActions('categories', ['getCategories']),
  },
}
</script>

<style lang="scss" scoped>

</style>
